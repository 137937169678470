import { Controller } from '@hotwired/stimulus'

export class NestedFormController extends Controller {
  targetTarget?: HTMLElement
  templateTarget?: HTMLElement
  wrapperSelectorValue: string = ''

  static targets = ['target', 'template']
  static values = {
    wrapperSelector: {
      type: String,
      default: '.nested-form-wrapper',
    },
  }

  add(e: Event): void {
    e.preventDefault()

    const count =
      document.getElementsByClassName('options-fields-wrapper').length + 1

    // @ts-ignore
    const content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime().toString())
      .replace(/Option/g, 'Option ' + count)

    this.targetTarget?.insertAdjacentHTML('beforebegin', content)

    const event = new CustomEvent('rails-nested-form:add', { bubbles: true })
    this.element.dispatchEvent(event)
  }

  remove(e: Event): void {
    e.preventDefault()

    const wrapper: HTMLElement | null = (e.target as HTMLElement).closest(
      this.wrapperSelectorValue
    )

    if (wrapper?.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      if (wrapper) wrapper.style.display = 'none'

      const input: HTMLInputElement | null | undefined = wrapper?.querySelector(
        "input[name*='_destroy']"
      )
      if (input) input.value = '1'
    }

    const fields = Array.from(
      document.getElementsByClassName('options-fields-wrapper')
    )

    for (const [idx, field] of fields.entries()) {
      const count = idx + 1
      const label = field.querySelector('label')

      if (label) label.innerHTML = 'Option ' + count
    }

    const event = new CustomEvent('rails-nested-form:remove', { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
