import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  observer?: MutationObserver

  observerConfig = {
    attributes: false,
    childList: true,
    subtree: false,
  }

  static values = {
    url: String,
  }

  declare urlValue: string

  connect(): void {
    this.setupObserver()
  }

  disconnect(): void {
    this.stopObserver()
  }

  private handleMutation: MutationCallback = (mutationList, observer): void => {
    post(this.urlValue, {
      contentType: 'application/json',
      responseKind: 'json',
      body: {},
    })
  }

  private setupObserver() {
    this.observer = new MutationObserver(this.handleMutation)
    this.observer.observe(this.element, this.observerConfig)
  }

  private stopObserver() {
    this.observer?.disconnect()
  }
}
