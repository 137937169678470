import { h, render } from 'preact'
import { Controller } from '@hotwired/stimulus'
import { WeekPicker } from '../components/WeekPicker'

export class WeekFilterController extends Controller {
  static values = {
    date: { type: String, default: new Date().toISOString() },
  }

  declare dateValue: string

  async connect() {
    let currentDate = new Date()
    if (this.dateValue) {
      currentDate = new Date(Date.parse(this.dateValue))
    }

    render(<WeekPicker date={currentDate} />, this.element)
  }
}
