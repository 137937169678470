import { Controller } from '@hotwired/stimulus'

export class CancellationFormController extends Controller {
  static targets = ['input', 'datepicker']
  static values = {
    action: String,
  }

  declare readonly datepickerTarget: HTMLInputElement
  declare readonly inputTargets: HTMLInputElement[]
  declare actionValue: string

  connect() {
    this.inputTargets[0].click()
  }

  actionValueChanged() {
    if (this.actionValue === 'scheduled') {
      this.datepickerTarget.classList.remove('hidden')
      this.datepickerTarget.disabled = false
    } else {
      this.datepickerTarget.classList.add('hidden')
      this.datepickerTarget.disabled = true
    }
  }

  toggle(event: any) {
    this.actionValue = event.target.value

    this.inputTargets.forEach(target => {
      let label = target.parentElement
      if (!label) return

      if (target.value === 'scheduled') label = label.parentElement
      if (!label) return

      const title = label.querySelector(
        '[data-id="cancellation-form-option-title"]'
      )
      const description = label.querySelector(
        '[data-id="cancellation-form-option-description"]'
      )

      if (target.value === event.target.value) {
        label.classList.add(
          'z-10',
          'border-primary-200',
          'dark:border-neutral-600',
          'bg-primary-50',
          'dark:bg-neutral-900/50'
        )
        label.classList.remove('border-gray-200', 'dark:border-neutral-700')

        if (title) {
          title.classList.add('text-primary-900', 'dark:text-neutral-100')
          title.classList.remove('text-gray-900', 'dark:text-neutral-300')
        }

        if (description) {
          description.classList.add('text-primary-700', 'dark:text-neutral-300')
          description.classList.remove('text-gray-500', 'dark:text-neutral-500')
        }
      } else {
        label.classList.add('border-gray-200', 'dark:border-neutral-700')
        label.classList.remove(
          'z-10',
          'border-primary-200',
          'dark:border-neutral-600',
          'bg-primary-50',
          'dark:bg-neutral-900/50'
        )

        if (title) {
          title.classList.remove('text-primary-900', 'dark:text-neutral-100')
          title.classList.add('text-gray-900', 'dark:text-neutral-300')
        }

        if (description) {
          description.classList.remove(
            'text-primary-700',
            'dark:text-neutral-300'
          )
          description.classList.add('text-gray-500', 'dark:text-neutral-500')
        }
      }
    })
  }
}
