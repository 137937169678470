import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ['sidebar', 'background', 'panel']

  toggle() {
    // // if (this.sidebarTarget.classList.contains('hidden')) {
    // if (this.sidebarTarget.style.display === 'none') {
    //   // enter(this.sidebarTarget)
    //   this.sidebarTarget.style.display = ''
    // } else {
    //   // leave(this.sidebarTarget).then(() => {
    //   //   this.sidebarTarget.classList.add('hidden')
    //   // })
    //   this.sidebarTarget.style.display = 'none'
    // }

    if (this.sidebarTarget.classList.contains('hidden')) {
      // if (this.sidebarTarget.style.display === 'none') {
      this.sidebarTarget.classList.remove('hidden')
      enter(this.backgroundTarget)
      enter(this.panelTarget)
      // enter(this.overlayTarget)
    } else {
      Promise.all([
        leave(this.backgroundTarget),
        leave(this.panelTarget),
        // leave(this.overlayTarget),
      ]).then(() => {
        this.sidebarTarget.classList.add('hidden')
      })
    }
  }

  submit(event) {
    const { name, checked, value, type } = event.target
    const url = new URL(window.location.href)

    if (type === 'checkbox') {
      if (/\[\]/.test(name)) {
        url.searchParams.set(name, value)
      } else if (checked) {
        url.searchParams.set(name, checked)
      }
    } else if (type !== 'checkbox' && value.length) {
      url.searchParams.set(name, value)
    } else {
      url.searchParams.delete(name)
    }

    Turbo.visit(url, { action: 'replace' })
  }
}
