export function redirect_to() {
  const url = this.getAttribute('url') || '/'
  const turboAction = this.getAttribute('turbo-action') || 'advance'
  const turboFrame = this.getAttribute('turbo-frame')
  const turbo = this.getAttribute('turbo') !== 'false'
  const options = {
    action: turboAction,
  }

  if (turboFrame) {
    options.frame = turboFrame
  }

  if (turbo && window.Turbo) {
    window.Turbo.visit(url, options)
  } else {
    Proxy.location.assign(url)
  }
}

export function turbo_clear_cache() {
  window.Turbo.cache.clear()
}
