import { ActionEvent, Controller } from '@hotwired/stimulus'

export class AssigneePickerController extends Controller<HTMLDivElement> {
  static targets: string[] = ['name', 'avatar', 'input']

  declare readonly nameTarget: HTMLSpanElement
  declare readonly avatarTarget: HTMLDivElement
  declare readonly inputTarget: HTMLInputElement

  selectAssignee = (event: ActionEvent) => {
    const id = event.params.id
    const name = (event.currentTarget as HTMLElement)?.querySelector(
      `[data-target="name"]`
    )
    const avatar = (event.currentTarget as HTMLElement)?.querySelector(
      `[data-target="avatar"]`
    )

    if (name) {
      this.nameTarget.innerHTML = name.innerHTML
    }
    if (avatar) {
      this.avatarTarget.innerHTML = avatar.innerHTML
    }

    this.inputTarget.value = id
    this.inputTarget.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: true,
      })
    )
  }
}
