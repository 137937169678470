import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { get } from '@rails/request.js'
import { useDebounce } from 'stimulus-use'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static debounces = ['inputChangedHandler']
  static targets = [
    'input',
    'results',
    'details',
    'wrapper',
    'background',
    'container',
    'outerContainer',
  ]
  static values = { open: Boolean }

  declare readonly inputTarget: HTMLInputElement
  declare readonly resultsTarget: HTMLUListElement
  declare readonly detailsTarget: HTMLDivElement
  declare readonly wrapperTarget: HTMLDivElement
  declare readonly backgroundTarget: HTMLDivElement
  declare readonly containerTarget: HTMLDivElement
  declare readonly outerContainerTarget: HTMLDivElement
  declare openValue: boolean
  declare readonly hasOpenValue: boolean

  activeIndex: number = 0
  capturedFocus: boolean = false
  toggleClasses = [
    'bg-gray-100',
    'text-gray-900',
    'dark:bg-neutral-700',
    'dark:text-neutral-100',
  ]
  hiddenClass = '!hidden'

  // initialize(): void {
  //   this.activeIndex = 0
  //   this.capturedFocus = false
  // }

  connect() {
    useDebounce(this, { wait: 300 })
    this.setupHotkeys()
    this.setupListener()
    this.setupHoverListener()
    this.setupClickListener()
    this.inputTarget.focus()
    document.addEventListener('keydown', this.toggleOpen)
  }

  disconnect(): void {
    this.resultsTarget.removeEventListener('mouseover', this.hoverListener)
    this.detailsTarget.removeEventListener('click', this.clickListener)
    this.inputTarget.removeEventListener('keydown', this.keyboardListener)
    this.inputTarget.removeEventListener('input', this.inputChangedHandler)
    document.removeEventListener('keydown', this.toggleOpen)
  }

  openValueChanged() {
    if (this.openValue) {
      this.show()
    } else {
      this.hide()
    }
  }

  private toggleOpen = (event: KeyboardEvent) => {
    if (event.metaKey && event.key === 'k') {
      event.preventDefault()
      this.openValue = !this.openValue
    }
  }

  private show() {
    this.inputTarget.value = ''
    this.wrapperTarget.classList.remove('hidden')
    enter(this.containerTarget)
    enter(this.backgroundTarget)
    this.inputTarget.focus()
    this.inputTarget.selectionStart = this.inputTarget.selectionEnd =
      this.inputTarget.value.length
  }

  close() {
    this.inputTarget.value = ''
    this.getSearchResults('')
    this.openValue = false
  }

  closeBackground(event) {
    if (event.target === this.outerContainerTarget) {
      this.close()
    }
  }

  private hide() {
    Promise.all([
      leave(this.backgroundTarget),
      leave(this.containerTarget),
    ]).then(() => {
      this.wrapperTarget.classList.add('hidden')
    })
  }

  private setupClickListener() {
    this.detailsTarget.addEventListener('click', this.clickListener)
  }

  private clickListener = event => {
    const targets = ['BUTTON', 'A']

    if (targets.includes(event.target.tagName)) {
      this.close()
    }
  }

  private setupHoverListener() {
    this.resultsTarget.addEventListener('mouseover', this.hoverListener)
  }

  private hoverListener = event => {
    if (!this.openValue) return

    if ('index' in event.target.dataset) {
      const index = parseInt(event.target.dataset.index)
      this.focusElementIndex(index, { scroll: false })
    }
  }

  private setupHotkeys() {
    this.inputTarget.addEventListener('keydown', this.keyboardListener)
  }

  keyboardListener = e => {
    if (!this.openValue) return

    switch (e.key) {
      case 'Escape':
        this.close()
        break
      case 'Enter':
        e.preventDefault()
        this.goToActiveIndex()
        break
      case 'Tab':
        if (!this.capturedFocus) {
          e.preventDefault()
          // used for first time, to capture focus, then we let the browser do it's thing
          this._focusFirstElement()
        }
        break
      case 'ArrowUp':
        this.upHandler()
        e.preventDefault()
        break
      case 'ArrowDown':
        this.downHandler()
        e.preventDefault()
        break
    }
  }

  private goToActiveIndex() {
    if (!this.keyboardFocusableElements.length) return

    const url = this.keyboardFocusableElements[this.activeIndex].dataset.url
    Turbo.visit(url)
  }

  private downHandler() {
    if (this.keyboardFocusableElements.length === 0) return

    if (!this.capturedFocus) {
      this._focusFirstElement()
    } else if (this.activeIndex === this.keyboardFocusableElements.length - 1) {
      this.activeIndex = 0
      this._focusFirstElement()
    } else {
      this.focusElementIndex(this.activeIndex + 1)
      this.activeIndex += 1
    }
  }

  _focusFirstElement() {
    if (this.keyboardFocusableElements.length === 0) return

    this.focusElementIndex(0)
    this.capturedFocus = true
  }

  _focusLastElement() {
    if (this.keyboardFocusableElements.length === 0) return

    const lastItemIndex = this.keyboardFocusableElements.length - 1
    this.focusElementIndex(lastItemIndex)
    this.activeIndex = lastItemIndex
  }

  private focusElementIndex(index: number, options = { scroll: true }) {
    this.keyboardFocusableElements.forEach((element, idx) => {
      if (idx === index) {
        element.classList.add(...this.toggleClasses)
        const id = element.id.replace('result_', '')
        this.detailsTarget
          .querySelector(`#detail_${id}`)
          ?.classList.remove(this.hiddenClass)
        element.querySelector('.chevron')?.classList.remove('hidden')
      } else {
        element.classList.remove(...this.toggleClasses)
        const id = element.id.replace('result_', '')
        this.detailsTarget
          .querySelector(`#detail_${id}`)
          ?.classList.add(this.hiddenClass)
        element.querySelector('.chevron')?.classList.add('hidden')
      }
    })
    if (options.scroll) {
      this.keyboardFocusableElements[index].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }

  private upHandler() {
    if (this.activeIndex === 0) {
      this._focusLastElement()
      return
    }

    if (this.capturedFocus && this.activeIndex >= 1) {
      this.focusElementIndex(this.activeIndex - 1)
      this.activeIndex -= 1
    }
  }

  private setupListener() {
    this.inputTarget.addEventListener('input', this.inputChangedHandler)
  }

  private inputChangedHandler = (event: any) => {
    this.getSearchResults(event.target.value)
  }

  private async getSearchResults(term: string) {
    try {
      this.activeIndex = 0
      await get('/search', {
        query: {
          q: term,
        },
        responseKind: 'turbo-stream',
      })
      setTimeout(() => {
        this._focusFirstElement()
      }, 100)
    } catch (error) {
      console.log(error)
    }
  }

  get keyboardFocusableElements() {
    return [...this.resultsTarget.querySelectorAll('[tabindex]')].filter(
      el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
    )
  }

  get detailElements() {
    return this.detailsTarget.querySelectorAll('.command-palette-detail')
  }
}
