import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  urlValue: string

  static values = {
    url: String,
  }

  dateChanged(event) {
    const url = new URL(window.location.href)
    url.searchParams.set('week_commencing', event.target.value)
    Turbo.visit(url, {
      action: 'replace',
    })
  }
}
