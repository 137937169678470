import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export class ModalController extends Controller {
  static targets: string[] = [
    'container',
    'backdrop',
    'panel',
    'closeButton',
    'overlay',
    'content',
  ]
  static values = {
    allowBackgroundClose: { type: Boolean, default: true },
    restoreScroll: { type: Boolean, default: true },
  }

  scrollPosition?: number

  declare readonly contentTarget: HTMLDivElement
  declare readonly containerTarget: HTMLDivElement
  declare readonly backdropTarget: HTMLDivElement
  declare readonly panelTarget: HTMLDivElement
  declare readonly overlayTarget: HTMLDivElement
  declare readonly closeButtonTarget: HTMLButtonElement
  declare readonly hasContentTarget: boolean
  declare readonly hasCloseButtonTarget: boolean
  declare readonly hasBackdropTarget: boolean
  declare readonly hasPanelTarget: boolean
  declare allowBackgroundCloseValue: Boolean
  declare restoreScrollValue: Boolean

  show(): void {
    this.lockScroll()
    this.containerTarget.classList.remove('hidden')
    if (this.hasBackdropTarget) enter(this.backdropTarget)
    if (this.hasCloseButtonTarget) enter(this.closeButtonTarget)
    if (this.hasPanelTarget) enter(this.panelTarget)
  }

  open() {
    this.show()
  }

  async hide(): Promise<void> {
    this.unlockScroll()
    const promises: any[] = []
    if (this.hasBackdropTarget) promises.push(leave(this.backdropTarget))
    if (this.hasCloseButtonTarget) promises.push(leave(this.closeButtonTarget))
    if (this.hasPanelTarget) promises.push(leave(this.panelTarget))
    await Promise.all(promises)
    this.containerTarget.classList.add('hidden')
  }

  close() {
    this.hide()
  }

  closeBackground(event) {
    if (event.target === this.overlayTarget && this.allowBackgroundCloseValue) {
      this.hide()
    }
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.removeProperty('paddingRight')

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    if (this.restoreScrollValue) {
      this.restoreScrollPosition()
    }

    // Remove the negative top inline style from body
    document.body.style.removeProperty('top')
  }

  saveScrollPosition() {
    this.scrollPosition = window.scrollY || document.body.scrollTop
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return

    document.documentElement.scrollTop = this.scrollPosition
  }
}
