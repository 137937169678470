import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export class LinkController extends Controller {
  static values = { url: String }

  declare urlValue: string

  visit() {
    const frame = this.element.closest('turbo-frame')
    if (frame) {
      Turbo.visit(this.urlValue, { frame: frame.id })
      // frame.src = this.urlValue
    } else {
      Turbo.visit(this.urlValue)
    }
  }
}
