import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

export class ArticleFormController extends Controller {
  static targets = [
    'changeCoverImageButton',
    'contentInput',
    'coverImageInput',
    'coverImagePreview',
    'dropzone',
    'removeCoverImageButton',
    'saveButton',
    'titleEditor',
    'titleInput',
  ]

  declare changeCoverImageButton: HTMLButtonElement
  declare removeCoverImageButton: HTMLButtonElement
  declare coverImageInputTarget: HTMLInputElement
  declare coverImagePreviewTarget: HTMLImageElement
  declare dropzoneTarget: HTMLDivElement
  declare saveButtonTarget: HTMLButtonElement
  declare titleEditorTarget: HTMLDivElement
  declare titleInputTarget: HTMLInputElement
  declare contentInputTarget: HTMLInputElement
  declare hasChangeCoverImageTarget: boolean
  declare hasRemoveCoverImageTarget: boolean
  declare hasCoverImageInputTarget: boolean
  declare hasCoverImagePreviewTarget: boolean
  declare hasDropzoneTarget: boolean
  declare hasTitleEditorTarget: boolean
  declare hasTitleInputTarget: boolean
  declare hasContentInputTarget: boolean

  connect(): void {
    this.setupListeners()
  }

  disconnect(): void {
    this.removeListeners()
  }

  removeCoverImage = (): void => {
    this.coverImageInputTarget.value = ''
    this.coverImagePreviewTarget.src = ''

    document
      .querySelectorAll(
        `input[name="${this.coverImageInputTarget.name}"][type="hidden"]`
      )
      .forEach(el => el.remove())
  }

  changeCoverImage = (): void => {
    this.coverImageInputTarget.click()
  }

  private setupListeners() {
    this.dropzoneTarget.addEventListener('dragenter', this.onDragOver, false)
    this.dropzoneTarget.addEventListener('dragover', this.onDragOver, false)
    this.dropzoneTarget.addEventListener('dragleave', this.onDrop, false)
    this.dropzoneTarget.addEventListener('drop', this.onDrop, false)
  }

  private removeListeners() {
    this.dropzoneTarget.removeEventListener('dragenter', this.onDragOver)
    this.dropzoneTarget.removeEventListener('dragover', this.onDragOver)
    this.dropzoneTarget.removeEventListener('dragleave', this.onDrop)
    this.dropzoneTarget.removeEventListener('drop', this.onDrop)
  }

  onTitleInput = () => {
    const content = this.titleEditorTarget.textContent || ''

    if (!content.trim().length) {
      this.titleEditorTarget.innerHTML = ''
    }

    this.titleInputTarget.value = content
  }

  onTitleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  onCoverImageChange = (_event: Event): void => {
    const file = this.coverImageInputTarget.files?.item(0)
    if (!file) return

    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 5) return alert('File is too big')

    this.previewFile(file)
    this.uploadFile(file)
  }

  private onDragOver = (_event: DragEvent): void => {
    this.dropzoneTarget.classList.add('border-primary-500')
    this.dropzoneTarget.classList.remove(
      'border-gray-900/25',
      'dark:border-neutral-600'
    )
  }

  private onDrop = (event: DragEvent): void => {
    event.preventDefault()
    this.dropzoneTarget.classList.remove('border-primary-500')
    this.dropzoneTarget.classList.add(
      'border-gray-900/25',
      'dark:border-neutral-600'
    )
    const files = event.dataTransfer?.files || []
    const file = files[0]
    if (!file) return

    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 5) return alert('File is too big')

    this.previewFile(file)
    this.uploadFile(file)
  }

  private previewFile(file: File): void {
    const fileReader = new FileReader()
    // @ts-ignore
    fileReader.onloadend = () => {
      const result = fileReader.result
      if (!result) return

      // @ts-ignore
      this.coverImagePreviewTarget.src = fileReader.result
    }

    fileReader.readAsDataURL(file)
  }

  private uploadFile = (file: File): void => {
    const url = this.coverImageInputTarget.dataset.directUploadUrl
    if (!url) return

    // @ts-ignore
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        // TODO: display the error message to the user
        console.log(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = this.coverImageInputTarget.name
        this.element.appendChild(hiddenField)
      }
    })
  }

  /**
   * Used as a delegate method for DirectUploadDelegate.
   * @param request {XMLHttpRequest} XHR request object
   */
  private directUploadWillStoreFileWithXHR = (request: XMLHttpRequest) => {
    request.upload.addEventListener('load', this.disableSaveButton)
    request.upload.addEventListener('error', this.enableSaveButton)
    request.upload.addEventListener('abort', this.enableSaveButton)
    request.upload.addEventListener('loadend', this.enableSaveButton)
  }

  private enableSaveButton = (_event: Event) => {
    this.saveButtonTarget.disabled = false
  }

  private disableSaveButton = (_event: Event) => {
    this.saveButtonTarget.disabled = true
  }
}
