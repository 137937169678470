import { Turbo } from '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import './controllers'
import './font-awesome'
import { scroll_into_view } from './stream-actions/browser'
import { set_theme } from './stream-actions/dom'
import { turbo_clear_cache } from './stream-actions/turbo'

ActiveStorage.start()

Turbo.setConfirmMethod((message, element) => {
  const dialogId = element.dataset.dialogTarget
  const dialog = document.getElementById(dialogId)

  if (!dialog) return window.confirm(message)

  dialog.showModal()

  return new Promise(resolve => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue === 'confirm')
      },
      { once: true }
    )
  })
})

Turbo.StreamActions.set_theme = set_theme
Turbo.StreamActions.scroll_into_view = scroll_into_view
Turbo.StreamActions.turbo_clear_cache = turbo_clear_cache

window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', e => {
    const isSystem = document.documentElement.classList.contains('system')
    const wantsDark = e.matches

    if (isSystem) {
      if (wantsDark) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }
  })
