import { Controller } from '@hotwired/stimulus'

export class WeighInController extends Controller<HTMLLinkElement> {
  connect(): void {
    document.addEventListener('keydown', this.onKeyDown)
  }

  disconnect(): void {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'w') {
      this.element.click()
    }
  }
}
