import { Controller } from '@hotwired/stimulus'
import EditorJS, { API, BlockMutationEvent } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Quote from '@editorjs/quote'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import LinkTool from '@editorjs/link'

export class EditorController extends Controller<HTMLInputElement> {
  container?: HTMLDivElement
  contentEditor?: EditorJS

  connect(): void {
    this.container = this.createContainer()
    this.element.insertAdjacentElement('afterend', this.container)
    this.contentEditor = new EditorJS({
      holder: this.container,
      data: this.getInitialData(),
      placeholder: 'Type your article here...',
      tools: {
        header: {
          class: Header,
          config: {
            levels: [2, 3, 4, 5, 6],
          },
        },
        list: List,
        quote: Quote,
        embed: Embed,
        table: {
          class: Table,
          config: {
            withHeadings: true,
          },
        },
        underline: Underline,
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: `/articles/images`,
            },
            types: 'image/png, image/jpg, image/jpeg, image/webp',
            additionalRequestHeaders: {
              'X-CSRF-Token': this.csrfToken,
            },
          },
        },
        link: {
          class: LinkTool,
          config: {
            endpoint: `/i/url-preview`,
          },
        },
      },
      onChange: this.onChangeEditor,
    })
    this.setupListener()
  }

  disconnect(): void {
    this.removeListener()
    this.contentEditor?.destroy()
    this.container?.remove()
  }

  get csrfToken() {
    const metaTag: HTMLMetaElement | null = document.querySelector(
      'meta[name="csrf-token"]'
    )
    return metaTag?.content
  }

  private onChangeEditor = async (api: API) => {
    const data = await api.saver.save()
    console.log('data', data)
    this.element.value = JSON.stringify(data)
  }

  private setupListener() {
    this.element.form?.addEventListener('submit', this.saveEditorData)
  }

  private removeListener() {
    this.element.form?.removeEventListener('submit', this.saveEditorData)
  }

  private saveEditorData = async (event: SubmitEvent) => {
    event.preventDefault()
    const data = await this.contentEditor?.save()
    this.element.value = JSON.stringify(data)
    this.element.form?.submit()
  }

  private getInitialData() {
    const value = this.element.value
    return value ? JSON.parse(value) : {}
  }

  private createContainer() {
    const container = document.createElement('div')
    container.classList.add('editor-canvas')

    return container
  }
}
