import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ['container', 'content', 'background']
  static values = {
    backdropColor: { type: String, default: 'rgba(0, 0, 0, 0.8)' },
    restoreScroll: { type: Boolean, default: true },
  }

  connect() {
    // The class we should toggle on the container
    this.toggleClass = this.data.get('class') || 'hidden'

    // The ID of the background to hide/remove
    this.backgroundId = this.data.get('backgroundId') || 'modal-background'

    // The HTML for the background element
    this.backgroundHtml =
      this.data.get('backgroundHtml') || this._backgroundHTML()

    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose =
      (this.data.get('allowBackgroundClose') || 'true') === 'true'

    // Prevent the default action of the clicked element (following a link for example) when opening the modal
    this.preventDefaultActionOpening =
      (this.data.get('preventDefaultActionOpening') || 'true') === 'true'

    // Prevent the default action of the clicked element (following a link for example) when closing the modal
    this.preventDefaultActionClosing =
      (this.data.get('preventDefaultActionClosing') || 'true') === 'true'

    this.setupListener()
    this.open()
  }

  disconnect() {
    this.removeListener()
    this.close()
  }

  open() {
    // Lock the scroll and save current scroll position
    this.lockScroll()

    // // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass)
    this.contentTarget.classList.remove(this.toggleClass)
    this.backgroundTarget.classList.remove(this.toggleClass)

    // Insert the background
    if (!this.data.get('disable-backdrop')) {
      // document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)
      this.background = document.querySelector(`#${this.backgroundId}`)
    }
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault()
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll()

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass)
    this.contentTarget.classList.add(this.toggleClass)

    // Remove the background
    if (this.background) {
      // this.background.remove()
      this.background.classList.add(this.toggleClass)
    }

    setTimeout(() => {
      if (!this.element.parentElement) return
      this.element.parentElement.removeAttribute('src')
      this.element.remove()
    }, 300)
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e)
    }
  }

  closeWithKeyboard(event) {
    if (
      event.keyCode === 27 &&
      !this.containerTarget.classList.contains(this.toggleClass) &&
      !['input', 'textarea'].includes(event.target.tagName.toLowerCase())
    ) {
      this.close(event)
    }
  }

  _backgroundHTML() {
    return `<div
      id="${this.backgroundId}"
      class="z-20 transform transition-all anim-fade-in fixed inset-0 bg-neutral-500 dark:bg-neutral-900 bg-opacity-50 dark:bg-opacity-75 anim-fade-in"
    ></div>`
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    if (this.restoreScrollValue) {
      this.restoreScrollPosition()
    }

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return

    document.documentElement.scrollTop = this.scrollPosition
  }

  onSubmitEnd = event => {
    if (event.detail.success && this.data.get('closeOnSuccess') === 'true') {
      this.close()
    }
  }

  setupListener() {
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  removeListener() {
    this.element.removeEventListener('turbo:submit-end', this.onSubmitEnd)
  }
}
