import { Controller } from '@hotwired/stimulus'
import { h, render } from 'preact'
import { format } from 'date-fns'
import { DateTimePicker } from '../components/DateTimePicker'

export class DateTimePickerController extends Controller<HTMLFormElement> {
  static targets: string[] = ['container', 'dateInput', 'timeInput']
  static values = {
    date: String,
    time: String,
  }

  declare readonly containerTarget: HTMLInputElement
  declare readonly dateInputTarget: HTMLInputElement
  declare readonly timeInputTarget: HTMLInputElement
  declare dateValue: string
  declare timeValue: string

  connect(): void {
    let date: Date | undefined

    if (this.dateValue) {
      // date = new Date(Date.parse(this.dateValue))
      date = new Date(this.dateValue)
    }

    render(
      <DateTimePicker
        date={date}
        time={this.timeValue}
        onChangeDate={this.onChangeDate}
        onChangeTime={this.onChangeTime}
      />,
      this.containerTarget
    )
  }

  onChangeDate = (date?: Date) => {
    this.dateInputTarget.value = date ? format(date, 'yyyy-MM-dd') : ''
    this.dateInputTarget.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: true,
      })
    )
  }

  onChangeTime = (time?: string) => {
    this.timeInputTarget.value = time || ''
    this.timeInputTarget.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: true,
      })
    )
  }
}
