import { Controller } from '@hotwired/stimulus'

export class ScrollToBottomController extends Controller {
  observer?: MutationObserver

  observerConfig = {
    attributes: false,
    childList: true,
    subtree: false,
  }

  connect(): void {
    this.setupObserver()
    this.scrollToBottom()
  }

  disconnect(): void {
    this.stopObserver()
  }

  private scrollToBottom = (): void => {
    this.element.scrollTop =
      this.element.scrollHeight - this.element.clientHeight
  }

  private setupObserver() {
    this.observer = new MutationObserver(this.scrollToBottom)
    this.observer.observe(this.element, this.observerConfig)
  }

  private stopObserver() {
    this.observer?.disconnect()
  }
}
