import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

export class TagsController extends Controller<HTMLInputElement> {
  tagify?: Tagify<Tagify.TagData>

  connect(): void {
    this.tagify = new Tagify(this.element, {
      originalInputValueFormat: valuesArr => {
        return JSON.stringify(valuesArr.map(item => item.value))
      },
    })
  }

  disconnect(): void {
    this.tagify?.destroy()
  }
}
