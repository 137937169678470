import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'target']

  connect() {
    const source = this.sourceTarget
    const target = this.targetTarget
    const groups = target.querySelectorAll(`optgroup`)
    this.options = Array.from(groups).map(group => {
      const label = group.getAttribute('label').trim()
      const options = Array.from(group.querySelectorAll('option'))
      return { label, options }
    })
    this.removeTargetGroups()

    if (source.selectedIndex > -1) {
      this.updateOptionsFromSource()
    }
  }

  sourceChanged(_event) {
    this.removeCurrentOptions()
    this.updateOptionsFromSource()
  }

  updateOptionsFromSource() {
    const source = this.sourceTarget
    const target = this.targetTarget
    const label = source.options[source.selectedIndex].text.trim()
    const selectedOption = this.options.find(group => group.label === label)
    if (!selectedOption) return

    const options = selectedOption.options

    options.forEach(option => {
      const optionElement = document.createElement('option')
      optionElement.value = option.value
      optionElement.text = option.text
      optionElement.selected = option.selected
      target.appendChild(optionElement)
    })
  }

  removeTargetGroups() {
    const target = this.targetTarget
    const groups = target.querySelectorAll(`optgroup`)
    groups.forEach(group => group.remove())
  }

  removeCurrentOptions() {
    const target = this.targetTarget
    const options = target.querySelectorAll('option:not([value=""])')
    options.forEach(option => option.remove())
  }
}
