import { h } from 'preact'
import classNames from 'classnames'

interface Props {
  onPress: () => void
  disabled?: boolean
}

export function PreviousMonthButton({ onPress, disabled }: Props) {
  return (
    <button
      disabled={disabled}
      onClick={onPress}
      type="button"
      class={classNames(
        'inline-flex p-1 transition duration-100 ease-in-out rounded-full focus:outline-none focus:shadow-outline',
        {
          'text-gray-400 dark:text-neutral-400 hover:bg-gray-100 dark:hover:bg-neutral-700 cursor-pointer':
            !disabled,
          'text-gray-200 dark:text-neutral-600 cursor-default': disabled,
        }
      )}
    >
      <svg
        class="inline-flex w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  )
}
