import { h } from 'preact'
import { isAfter, isSameDay, isToday } from 'date-fns'
import classNames from 'classnames'
import { DayObject } from '../WeekPicker'

interface Props {
  date?: Date
  year: number
  month: number
  week: DayObject[]
  onClick: (date: Date) => void
}

export function WeekRow({ date, month, year, week, onClick }: Props) {
  return (
    <div class="grid grid-cols-7 rounded-lg">
      {week.map(day => {
        const currentDate = new Date(year, month, day.day)
        const isFuture = isAfter(currentDate, new Date())
        const isDateToday = isToday(currentDate)
        const isCurrent = isDateToday || isFuture
        const isSelected = !!date && isSameDay(date, currentDate)

        if (day.type === 'blank') {
          return (
            <div
              key={`dayInMonth_${day.day}`}
              class="p-1 text-sm text-center border border-transparent"
            ></div>
          )
        }

        return (
          <div
            key={`dayInMonth_${day.day}`}
            class="px-0.5 my-0.5 aspect-square flex items-center justify-center"
          >
            <button
              disabled={!isCurrent}
              onClick={() => onClick(currentDate)}
              class={classNames(
                'flex items-center justify-center text-sm leading-none text-center rounded-full h-7 w-7',
                {
                  'text-gray-200 dark:text-neutral-600': !isCurrent,
                  'bg-gray-200 dark:bg-neutral-600': isDateToday,
                  'text-gray-600 dark:text-neutral-400':
                    !isDateToday || !date || isSameDay(date, currentDate),
                  'bg-primary-800 text-white dark:text-white': isSelected,
                  'hover:bg-gray-300 dark:hover:bg-neutral-600': isCurrent,
                }
              )}
            >
              {day.day}
            </button>
          </div>
        )
      })}
    </div>
  )
}
