import { Controller } from '@hotwired/stimulus'

export class ToggleContentController extends Controller {
  static values = {
    content: String,
  }

  previousContent?: string

  declare contentValue?: string

  connect(): void {
    this.previousContent = this.element.innerHTML
    this.element.addEventListener('click', this.toggle)
  }

  disconnect(): void {
    this.element.removeEventListener('click', this.toggle)
  }

  toggle = (): void => {
    if (this.element.innerHTML === this.previousContent) {
      this.element.innerHTML = this.contentValue || ''
    } else {
      this.element.innerHTML = this.previousContent || ''
    }
  }
}
