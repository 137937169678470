import { Controller } from '@hotwired/stimulus'

export class CurrentConversationController extends Controller {
  connect(): void {
    this.setupListener()
    this.setActiveConversation()
  }

  disconnect(): void {
    this.removeListener()
  }

  private setupListener() {
    this.element.addEventListener(
      'turbo:frame-load',
      this.setActiveConversation
    )
  }

  private removeListener() {
    this.element.removeEventListener(
      'turbo:frame-load',
      this.setActiveConversation
    )
  }

  private setActiveConversation() {
    const parts = window.location.pathname.split('/')
    const conversationId = parts[parts.length - 1]
    const elementId = `conversation_${conversationId}`

    document.querySelectorAll('.conversation-link').forEach(element => {
      if (element.parentElement?.id === elementId) {
        element.classList.add('bg-primary-600/10', 'dark:bg-neutral-700')
        element.classList.remove(
          'text-gray-900',
          'dark:text-neutral-500',
          'hover:bg-gray-50',
          'hover:dark:bg-neutral-800'
        )
      } else {
        element.classList.remove('bg-primary-600/10', 'dark:bg-neutral-700')
        element.classList.add(
          'text-gray-900',
          'dark:text-neutral-500',
          'hover:bg-gray-50',
          'hover:dark:bg-neutral-800'
        )
      }
    })
  }
}
