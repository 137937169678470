import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      forceFallback: true,
      handle: '.drag-handle',
      store: {
        set: sortable => {
          const keys = sortable.toArray()

          post(this.urlValue, {
            body: { keys },
          })
        },
      },
    })
  }

  disconnect() {
    this.sortable.destroy()
  }
}
