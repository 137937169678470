import { Controller } from '@hotwired/stimulus'

export class AutoSubmitController extends Controller<HTMLFormElement> {
  connect(): void {
    this.element.addEventListener('change', this.submitForm)
  }

  disconnect(): void {
    this.element.removeEventListener('change', this.submitForm)
  }

  submitForm = (): void => {
    this.element.requestSubmit()
  }
}
