import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  refreshTimer: number
  refreshIntervalValue = 10000

  connect(): void {
    this.startRefreshing()
  }

  disconnect(): void {
    this.stopRefreshing()
  }

  startRefreshing(): void {
    this.refreshTimer = setInterval(() => {
      this.fetch()
    }, this.refreshIntervalValue)
  }

  stopRefreshing(): void {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  fetch(): void {
    get(`/`, {
      responseKind: 'turbo-stream',
    })
  }
}
