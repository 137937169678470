import { h } from 'preact'
import { useEffect, useState, useRef, useCallback } from 'preact/hooks'
import {
  addDays,
  addWeeks,
  format,
  isBefore,
  isPast,
  isSameMonth,
  isThisWeek,
  isToday,
  isTomorrow,
  nextMonday,
  startOfTomorrow,
} from 'date-fns'
import { PreviousMonthButton } from './WeekPicker/PreviousMonthButton'
import { NextMonthButton } from './WeekPicker/NextMonthButton'
import { WeekRow } from './DateTimePicker/WeekRow'
import classNames from 'classnames'

const DAY_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const MONTH_NAMES: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function formatDateText(date?: Date): string {
  if (!date) return 'Due date'

  if (isPast(date)) return format(date, 'd LLL yyyy')

  if (isThisWeek(date) || isBefore(date, addWeeks(new Date(), 1)))
    return format(date, 'EEEE')

  if (isSameMonth(date, new Date())) return format(date, 'd LLL')

  return format(date, 'd LLL yyyy')
}

function groupArray<T>(array: T[], num: number): T[][] {
  const group: T[][] = []

  for (let i = 0; i < array.length; i += num) {
    group.push(array.slice(i, i + num))
  }

  return group
}

function useOutsideClick(ref: any, onClickOut: () => void, deps: any[] = []) {
  useEffect(() => {
    const onClick = ({ target }: any) => {
      !ref?.contains(target) && onClickOut?.()
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, deps)
}

interface Props {
  date?: Date
  time?: string
  onChangeDate: (date?: Date) => void
  onChangeTime: (time?: string) => void
}

export interface DayObject {
  day: number
  type: 'blank' | 'normal'
}

export function DateTimePicker({
  date: initialDate,
  date: defaultDate = new Date(),
  onChangeDate,
  onChangeTime,
}: Props) {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(initialDate)
  const [month, setMonth] = useState(defaultDate.getMonth())
  const [year, setYear] = useState(defaultDate.getFullYear())
  const [blankDaysInMonth, setBlankDaysInMonth] = useState<number[]>([])
  const [daysInMonth, setDaysInMonth] = useState<number[]>([])

  const containerRef = useRef<HTMLDivElement>()
  const today = useRef(new Date()).current
  const isDateToday = !!date && isToday(date)
  const isDateTomorrow = !!date && isTomorrow(date)

  const allDays: DayObject[] = [
    ...blankDaysInMonth.map(day => ({
      day,
      type: 'blank',
    })),
    ...daysInMonth.map(day => ({
      day,
      type: 'normal',
    })),
  ]
  const groupedDays = groupArray(allDays, 7)
  const atMinMonth = today.getFullYear() === year && today.getMonth() === month

  useEffect(() => {
    document.addEventListener('keyup', closeWithKeyboard)

    return () => {
      document.removeEventListener('keyup', closeWithKeyboard)
    }
  }, [])

  useOutsideClick(
    containerRef.current,
    () => {
      setOpen(false)
    },
    [containerRef.current]
  )

  const toggleOpen = useCallback(() => {
    setOpen(currentValue => !currentValue)
  }, [])

  function datePickerCalculateDays() {
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate()
    // find where to start calendar day of week
    const dayOfWeek = new Date(year, month).getDay()
    const dayIndex = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    const blankDaysArray: number[] = []

    for (var i = 1; i <= dayIndex; i++) {
      blankDaysArray.push(i)
    }

    const daysArray: number[] = []
    for (var i = 1; i <= totalDaysInMonth; i++) {
      daysArray.push(i)
    }

    setBlankDaysInMonth(blankDaysArray)
    setDaysInMonth(daysArray)
  }

  useEffect(() => {
    datePickerCalculateDays()
  }, [date])

  useEffect(() => {
    datePickerCalculateDays()
  }, [month])

  const closeWithKeyboard = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }, [])

  const onDateClick = useCallback(
    (date?: Date) => {
      setDate(date)
      setOpen(false)
      onChangeDate(date)
    },
    [onChangeDate]
  )

  const selectToday = useCallback(() => {
    const date = new Date()
    onDateClick(date)
  }, [onChangeDate])

  const selectTomorrow = useCallback(() => {
    const date = addDays(new Date(), 1)
    onDateClick(date)
  }, [onDateClick])

  const selectNextWeek = useCallback(() => {
    const date = nextMonday(new Date())
    onDateClick(date)
  }, [onDateClick])

  const clearDate = useCallback(() => {
    onDateClick()
  }, [onDateClick])

  function datePickerPreviousMonth() {
    if (atMinMonth) return

    if (month === 0) {
      setYear(year - 1)
      setMonth(11)
    } else {
      setMonth(month - 1)
    }
  }

  function datePickerNextMonth() {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  return (
    <div ref={containerRef} class="container block mb-1">
      <div class="w-full">
        <label id="listbox-label" class="sr-only">
          Add a due date
        </label>
        <div class="relative">
          <button
            onClick={toggleOpen}
            type="button"
            data-action="click->dropdown#toggle click@window->dropdown#hide"
            data-dropdown-target="button"
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 rounded-full dark:text-neutral-300 whitespace-nowrap bg-gray-50 dark:bg-neutral-700 hover:bg-gray-100 dark:hover:bg-neutral-600 sm:px-3"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
          >
            <svg
              class={classNames('flex-shrink-0 w-5 h-5 sm:-ml-1', {
                'text-gray-500 dark:text-neutral-300': !!date,
                'text-gray-300 dark:text-neutral-500': !date,
                'text-red-700 dark:text-red-500': !!date && isPast(date),
              })}
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              class={classNames('hidden truncate sm:ml-2 sm:block', {
                'text-gray-900 dark:text-neutral-300': !!date,
                'text-red-700 dark:text-red-500': !!date && isPast(date),
              })}
            >
              {formatDateText(date)}
            </span>
          </button>

          {open && (
            <div class="absolute -bottom-52 left-[6.7rem] max-w-lg px-4 py-2 mt-12 antialiased bg-white dark:bg-neutral-800 border rounded-lg shadow w-[17rem] border-gray-200/70 dark:border-neutral-700">
              <div class="flex flex-col items-stretch -mx-4 pb-2 mb-2 border-b border-gray-200/70 dark:border-neutral-700">
                {!isDateToday && (
                  <button
                    onClick={selectToday}
                    type="button"
                    class="px-4 py-2 text-left text-sm flex justify-between text-gray-900 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700"
                  >
                    <span>Today</span>
                    <span>{format(new Date(), 'EEE')}</span>
                  </button>
                )}
                {!isDateTomorrow && (
                  <button
                    onClick={selectTomorrow}
                    type="button"
                    class="px-4 py-2 text-left text-sm flex justify-between text-gray-900 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700"
                  >
                    <span>Tomorrow</span>
                    <span>{format(startOfTomorrow(), 'EEE')}</span>
                  </button>
                )}
                <button
                  onClick={selectNextWeek}
                  type="button"
                  class="px-4 py-2 text-left text-sm flex justify-between text-gray-900 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700"
                >
                  <span>Next week</span>
                  <span>{format(nextMonday(new Date()), 'EEE LLL d')}</span>
                </button>
                {!!date && (
                  <button
                    onClick={clearDate}
                    type="button"
                    class="px-4 py-2 text-left text-sm text-gray-900 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700"
                  >
                    No date
                  </button>
                )}
              </div>
              <div class="flex items-center justify-between mb-2">
                <div>
                  <span class="text-lg font-bold text-gray-800 dark:text-neutral-300">
                    {MONTH_NAMES[month]}
                  </span>
                  <span class="ml-1 text-lg font-normal text-gray-600 dark:text-neutral-500">
                    {year}
                  </span>
                </div>
                <div>
                  <PreviousMonthButton
                    onPress={datePickerPreviousMonth}
                    disabled={atMinMonth}
                  />
                  <NextMonthButton onPress={datePickerNextMonth} />
                </div>
              </div>
              <div class="grid grid-cols-7 mb-3">
                {DAY_NAMES.map(day => (
                  <div key={day} class="px-0.5">
                    <div class="text-xs font-medium text-center text-gray-800 dark:text-neutral-400">
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              {groupedDays.map(group => (
                <WeekRow
                  key={group}
                  date={date}
                  week={group}
                  month={month}
                  year={year}
                  onClick={onDateClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
