import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'select']

  connect() {
    if (!this.hasSelectTarget) return

    const { value } = this.selectTarget
    this.toggleVisibilityForType(value)
  }

  changeType(event) {
    const { value } = event.target

    this.toggleVisibilityForType(value)
  }

  toggleVisibilityForType(type) {
    if (['select', 'checkboxes'].includes(type)) {
      this.containerTarget.classList.remove('hidden')
    } else {
      this.containerTarget.classList.add('hidden')
    }
  }
}
