import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
// Change the config to fix the flicker
config.mutateApproach = 'sync'

import {
  faBalanceScale,
  faBalanceScaleLeft,
  faBalanceScaleRight,
  faBars,
  faBarsFilter,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faCopy,
  faEllipsis,
  faEllipsisV,
  faGripVertical,
  faMoon,
  faSeal,
  faSun,
  faTimes,
  faUserCheck,
  faUserFriends,
  faUserTimes,
  faWreathLaurel,
  faTrophy as solidTrophy,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faClock as faClockRegular,
  faComment,
  faPlus,
  faQuestionCircle,
  faCalendar as faCalendarRegular,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faArrowDownFromLine,
  faArrowLeftToLine,
  faArrowUpRightFromSquare,
  faArrowsToLine,
  faArrowTrendDown,
  faArrowTrendUp,
  faArrowUpFromLine,
  faAward,
  faBullseye,
  faCalendar,
  faCalendarCheck,
  faChartLine,
  faClock,
  faCog,
  faCommentQuote,
  faDash,
  faEdit,
  faEnvelope,
  faFileSignature,
  faLockKeyhole,
  faMessage,
  faPenToSquare,
  faPhone,
  faScaleBalanced,
  faStopwatch,
  faTachometerAlt,
  faTrophy,
  faTrophyStar,
  faUserEdit,
  faUserGear,
  faUserGroup,
  faUsers,
  faUsersCog,
  faWeight,
  faMessageCaptions,
  faSparkles,
  faUsersSlash,
  faMugHot,
  faSearch,
  faPenLine,
  faClockRotateLeft,
  faInbox,
  faRobot,
  faCreditCard,
  faCalendarStar,
  faMegaphone,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faArrowDownFromLine,
  faArrowUpFromLine,
  faArrowUpRightFromSquare,
  faArrowsToLine,
  faArrowLeftToLine,
  faArrowTrendDown,
  faArrowTrendUp,
  faAward,
  faBalanceScale,
  faBalanceScaleLeft,
  faBalanceScaleRight,
  faBullseye,
  faBars,
  faBarsFilter,
  faCalendar,
  faCalendarCheck,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClock,
  faClockRegular,
  faCog,
  faCopy,
  faCommentQuote,
  faDash,
  faEdit,
  faEllipsis,
  faEllipsisV,
  faEnvelope,
  faFileSignature,
  faGripVertical,
  faLockKeyhole,
  faMessage,
  faMessageCaptions,
  faMoon,
  faMugHot,
  faPenToSquare,
  faPhone,
  faQuestionCircle,
  faSeal,
  faScaleBalanced,
  faSparkles,
  faStopwatch,
  faSun,
  faTachometerAlt,
  faTimes,
  faTrophy,
  faTrophyStar,
  faUserCheck,
  faUserEdit,
  faUserFriends,
  faUserGear,
  faUserGroup,
  faUsers,
  faUsersCog,
  faUsersSlash,
  faUserTimes,
  faWeight,
  faSearch,
  faWreathLaurel,
  solidTrophy,
  faPenLine,
  faClockRotateLeft,
  faPlus,
  faCirclePlus,
  faInbox,
  faTrashCan,
  faComment,
  faCalendarRegular,
  faRobot,
  faCreditCard,
  faCalendarStar,
  faMegaphone
)

dom.watch()
