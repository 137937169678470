import { h } from 'preact'
import { DayObject } from '../WeekPicker'
import { isAfter, isSameDay } from 'date-fns'
import classNames from 'classnames'

interface Props {
  date: Date
  year: number
  month: number
  week: DayObject[]
  onClick: (day: number) => void
}

export function WeekRow({ date, month, year, week, onClick }: Props) {
  const firstDay = new Date(year, month, week[0].day)
  const hasMonday = firstDay.getDay() === 1
  const isFuture = isAfter(firstDay, new Date())

  function dateIsToday(day: number) {
    const today = new Date()
    const date = new Date(year, month, day)

    return isSameDay(today, date)
  }

  function datePickerIsSelectedDate(day: number): boolean {
    const d = new Date(year, month, day)
    return isSameDay(date, d)
  }

  return (
    <div
      onClick={() => {
        if (hasMonday && !isFuture) {
          onClick(week[0].day)
        }
      }}
      class={classNames('grid grid-cols-7 rounded-lg', {
        'hover:bg-gray-200 dark:hover:bg-neutral-700 group cursor-pointer':
          hasMonday && !isFuture,
      })}
    >
      {week.map((day, dayIndex) => {
        if (day.type === 'blank') {
          return (
            <div
              key={`dayInMonth_${day.day}`}
              class="p-1 text-sm text-center border border-transparent"
            ></div>
          )
        }

        return (
          <div
            key={`dayInMonth_${day.day}`}
            class="px-0.5 my-0.5 aspect-square flex items-center justify-center"
          >
            <div
              class={classNames(
                'flex items-center justify-center text-sm leading-none text-center rounded-full h-7 w-7',
                {
                  'text-gray-200 dark:text-neutral-600': isFuture,
                  'bg-gray-200 dark:bg-neutral-600':
                    dateIsToday(day.day) == true,
                  'text-gray-600 dark:text-neutral-400':
                    dateIsToday(day.day) == false &&
                    datePickerIsSelectedDate(day.day) == false &&
                    !isFuture,
                  'bg-primary-800 text-white': datePickerIsSelectedDate(
                    day.day
                  ),
                  'group-hover:bg-gray-300 dark:group-hover:bg-neutral-600':
                    hasMonday && dayIndex === 0,
                }
              )}
            >
              {day.day}
            </div>
          </div>
        )
      })}
    </div>
  )
}
