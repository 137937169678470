import { divmod } from './math-utils'

export function toStonesAndPounds(value) {
  const negative = value < 0
  const correctValue = Math.abs(parseFloat(`${value}`))

  const [stones, pounds] = divmod(correctValue, 14)

  const weight =
    stones < 1
      ? `${parseFloat(pounds.toFixed(1))} lbs`
      : `${stones} st ${parseFloat(pounds.toFixed(1))} lbs`

  return negative ? `-${weight}` : weight
}
