import tippy, { Instance, Props } from 'tippy.js'
import { Controller } from '@hotwired/stimulus'

export class TooltipController extends Controller {
  static values = {
    message: String,
    followCursor: String,
  }

  declare tippyInstance: Instance<Props>
  declare messageValue: string
  declare followCursorValue?: 'horizontal' | 'vertical' | 'initial'

  connect(): void {
    this.tippyInstance = tippy(this.element, {
      animation: 'scale',
      content: this.messageValue,
      inertia: true,
      followCursor: this.followCursorValue,
    })
  }

  disconnect(): void {
    this.tippyInstance.destroy()
  }
}
