import { Controller } from '@hotwired/stimulus'
// import Chartkick from 'chartkick'
import {
  Chart,
  LineController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import { toStonesAndPounds } from '../utils/weight-utils'

Chart.register(
  LineController,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
)

function getLabels(weekData) {
  return Object.keys(weekData).reverse()
}

export default class extends Controller {
  static targets = ['chart']
  static values = {
    data: Object,
  }

  connect() {
    if (!this.hasChartTarget) return

    // const [theme] = useTheme()
    // const textColour = theme === 'dark' ? '#fff' : '#333'
    // const borderColour =
    //   theme === 'dark' ? tailwindColours.gray[900] : tailwindColours.gray[100]
    // "100": "#f5f5f5"
    const borderColour = '#171717'
    const textColour = '#333'
    const computedStyle = getComputedStyle(document.body)

    const data = this.dataValue
    new Chart(this.chartTarget, {
      type: 'line',
      borderColor: borderColour,
      responsive: true,
      options: {
        datasets: {
          line: {
            spanGaps: true,
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              color: textColour,
            },
          },
          tooltip: {
            callbacks: {
              label: ({ raw }) => {
                return toStonesAndPounds(raw)
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              // stepSize: 7,
              stepSize: 14,
              callback: value => {
                return toStonesAndPounds(value)
              },
            },
          },
        },
      },
      data: {
        labels: getLabels(data),
        datasets: [
          {
            // backgroundColor: GRAPH_COLOURS,
            label: 'Weight history',
            data: Object.values(data).reverse(),
            borderColor: computedStyle.getPropertyValue('--color-primary-400'),
            backgroundColor: computedStyle.getPropertyValue(
              '--color-primary-500'
            ),
          },
        ],
      },
    })
  }
}
