export function set_theme() {
  const theme = this.getAttribute('theme')

  if (theme === 'dark') {
    document.documentElement.classList.remove('light')
  } else if (theme === 'light') {
    document.documentElement.classList.remove('dark')
  } else {
    const prefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches

    if (prefersDark) {
      document.documentElement.classList.remove('light')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  document.documentElement.classList.add(theme)
}
