import { Controller } from '@hotwired/stimulus'

export class CheckboxButtonController extends Controller<HTMLButtonElement> {
  static outlets: string[] = ['label']

  declare readonly labelOutlet: HTMLLinkElement

  public handleClick = (_event: Event) => {
    console.log('handleClick')
    const nextState = this.element.ariaChecked === 'true' ? 'false' : 'true'
    const taskElement: HTMLLIElement | null =
      this.element.closest('[id^="task_"]')
    console.log('taskElement', taskElement)

    this.element.setAttribute('aria-checked', nextState)

    if (taskElement) {
      if (nextState === 'true') {
        taskElement.dataset.taskComplete = 'true'
      } else {
        taskElement.dataset.taskComplete = 'false'
      }
    }

    this.submitForm()
  }

  private submitForm(): void {
    setTimeout(() => {
      this.element.form?.requestSubmit()
    }, 350)
  }
}
