import { Controller } from '@hotwired/stimulus'
import 'form-request-submit-polyfill'

export default class extends Controller<HTMLFormElement> {
  static targets = ['cancelButton']

  declare readonly cancelButtonTarget: HTMLButtonElement
  declare readonly hasCancelButtonTarget: boolean

  connect(): void {
    this.setupSubmitListener()
    this.setupKeydownListener()
  }

  disconnect(): void {
    this.removeSubmitListener()
    this.removeKeydownListener()
  }

  reset() {
    this.element.reset()
  }

  onSubmitEnd = (event: any): void => {
    if (event.detail.success) {
      this.reset()
    }
  }

  setupSubmitListener() {
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  removeSubmitListener() {
    this.element.removeEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.element.requestSubmit()
    }

    if (event.code === 'Escape' && this.hasCancelButtonTarget) {
      event.preventDefault()
      this.cancelButtonTarget.click()
    }
  }

  setupKeydownListener() {
    this.element.addEventListener('keydown', this.onKeyDown)
  }

  removeKeydownListener() {
    this.element.removeEventListener('keydown', this.onKeyDown)
  }
}
