import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static outlets = ['command-palette']

  static targets = ['navbar', 'sidebar', 'background', 'panel', 'overlay']

  toggleSidebar() {
    if (this.sidebarTarget.classList.contains('hidden')) {
      this.sidebarTarget.classList.remove('hidden')
      enter(this.backgroundTarget)
      enter(this.panelTarget)
      enter(this.overlayTarget)
    } else {
      Promise.all([
        leave(this.backgroundTarget),
        leave(this.panelTarget),
        leave(this.overlayTarget),
      ]).then(() => {
        this.sidebarTarget.classList.add('hidden')
      })
    }
  }

  toggleCommandPalette(event) {
    event.preventDefault()
    event.stopPropagation()
    this.commandPaletteOutlet.openValue = !this.commandPaletteOutlet.openValue
  }
}
