import { Controller } from '@hotwired/stimulus'
import { ModalController } from './modal_controller'
import Croppie from 'croppie'
import { DirectUpload } from '@rails/activestorage'
import Compressor from 'compressorjs'

export class AvatarPickerController extends Controller<HTMLInputElement> {
  static outlets: string[] = ['modal']
  static targets: string[] = ['input', 'preview']

  declare readonly modalOutlet: ModalController
  declare readonly inputTarget: HTMLInputElement
  declare readonly previewTarget: HTMLDivElement
  declare readonly hasInputTarget: boolean
  declare readonly hasPreviewTarget: boolean

  cropper?: Croppie

  connect(): void {
    if (!this.hasInputTarget) return
    this.inputTarget.addEventListener('change', this.onFileChange)
  }

  disconnect(): void {
    if (!this.hasInputTarget) return
    this.inputTarget.removeEventListener('change', this.onFileChange)
  }

  async confirmSelection(): Promise<void> {
    if (!this.cropper) return

    try {
      const blob = await this.cropper.result({
        circle: false,
        format: 'webp',
        quality: 1,
        size: 'original',
        type: 'blob',
      })

      const compressedFile = await this.compressFile(blob)
      if (!compressedFile) return // TODO: display an error here

      this.inputTarget.value = ''
      this.uploadFile(compressedFile)

      if (this.hasPreviewTarget) {
        const image = new Image()
        const objectURL = URL.createObjectURL(blob)
        const { height, width } = this.previewTarget.getBoundingClientRect()
        image.src = objectURL
        image.height = height
        image.width = width
        this.previewTarget.replaceChildren(image)
      }
      this.modalOutlet.hide()
    } catch (error) {
      console.log(error)
    }
  }

  private compressFile = async (blob: Blob): Promise<File | undefined> => {
    try {
      const result = await new Promise((resolve, reject) => {
        new Compressor(blob, {
          quality: 0.8,
          success: resolve,
          error: reject,
          strict: true,
          checkOrientation: true,
        })
      })

      const now = new Date().getTime()
      const file = new File([result as Blob], `avatar-${now}.webp`)
      return file
    } catch (error) {
      console.log(error)
    }
  }

  private uploadFile = (file: File): void => {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const input = this.inputTarget
    const url = input.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log('Error uploading file', error)
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = input.name
        this.inputTarget.after(hiddenField)
      }
    })
  }

  private onFileChange = (_event: Event): void => {
    const input = this.inputTarget
    const files = input.files
    if (!files) return

    const modal = this.modalOutlet
    const panel = modal.contentTarget
    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      if (!reader.result) return

      const image = new Image()
      image.src = reader.result
      image.height = 200
      panel.replaceChildren(image)

      this.cropper = new Croppie(image, {
        // enableResize: true,
        // enableZoom: false,
        customClass: 'h-80',
        showZoomer: true,
        viewport: {
          height: 320,
          width: 320,
          type: 'circle',
        },
      })

      // FIXME: there is a slight zoom issue on mount
      this.cropper.setZoom(50)
      // this.cropper.setZoom(0)
    }

    reader.readAsDataURL(file)

    modal.show()
  }
}
