import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.updatePermissions.bind(this))
    })
  }

  updatePermissions(event) {
    const { checked, value } = event.target

    if (/members:/.test(value)) {
      if (value !== 'members:read' && checked) {
        this.checkReadCheckbox('members:read')
      }
      if (value === 'members:read' && !checked) {
        this.checkboxTargets.forEach(checkbox => {
          if (checkbox.value !== 'members:read') checkbox.checked = false
        })
      }
    } else {
      const namespace = value.split(':')[0]
      const readValue = `${namespace}:read`
      if (value !== readValue && checked) this.checkReadCheckbox(readValue)
      if (value === readValue && !checked) {
        this.checkboxTargets.forEach(checkbox => {
          if (checkbox.value !== readValue) checkbox.checked = false
        })
      }
    }
  }

  checkReadCheckbox(value) {
    const checkbox = this.checkboxTargets.find(
      checkbox => checkbox.value === value
    )
    if (!checkbox) return

    checkbox.checked = true
    // TODO: come up with a solution that works here, like disabling and enabling the checkbox on submit
    // checkbox.readonly = true
  }
}
