import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import 'form-request-submit-polyfill'

export default class extends Controller<HTMLFormElement> {
  static targets: string[] = ['sendOnEnterButton', 'toggle']
  declare readonly hasSendOnEnterButtonTarget: boolean
  declare readonly sendOnEnterButtonTarget: HTMLButtonElement
  declare readonly sendOnEnterButtonTargets: HTMLButtonElement[]
  declare readonly hasToggleTarget: boolean
  declare readonly toggleTarget: HTMLSpanElement
  declare readonly toggleTargets: HTMLSpanElement[]

  static values = {
    sendOnEnter: { type: Boolean, default: false },
  }
  declare sendOnEnterValue: boolean

  connect(): void {
    this.setupSubmitListener()
    this.setupEnterListener()
  }

  disconnect(): void {
    this.removeSubmitListener()
    this.removeEnterListener()
  }

  sendOnEnterValueChanged(value, previousValue) {
    if (value === previousValue) return

    if (value) {
      this.sendOnEnterButtonTarget.classList.add('bg-primary-600')
      this.sendOnEnterButtonTarget.classList.remove(
        'bg-gray-200',
        'dark:bg-neutral-700'
      )
      this.toggleTarget.classList.add('translate-x-5')
      this.toggleTarget.classList.remove('translate-x-0')
    } else {
      this.sendOnEnterButtonTarget.classList.remove('bg-primary-600')
      this.sendOnEnterButtonTarget.classList.add(
        'bg-gray-200',
        'dark:bg-neutral-700'
      )
      this.toggleTarget.classList.add('translate-x-0')
      this.toggleTarget.classList.remove('translate-x-5')
    }

    patch('/account/settings', {
      contentType: 'application/json',
      responseKind: 'json',
      body: {
        user: {
          settings_attributes: {
            send_message_on_enter: value,
          },
        },
      },
    })
  }

  reset() {
    this.element.reset()
  }

  onSubmitEnd = (event: any): void => {
    if (event.detail.success) {
      this.reset()
    }
  }

  setupSubmitListener() {
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  removeSubmitListener() {
    this.element.removeEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  toggleSendOnEnter() {
    this.sendOnEnterValue = !this.sendOnEnterValue
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Enter' && this.sendOnEnterValue) {
      event.preventDefault()
      this.element.requestSubmit()
    }
  }

  setupEnterListener() {
    this.element.addEventListener('keydown', this.onKeyDown)
  }

  removeEnterListener() {
    this.element.removeEventListener('keydown', this.onKeyDown)
  }
}
