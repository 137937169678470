import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['input']

  submit(event) {
    event.preventDefault()
  }

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const value = event.target.value
      const url = new URL(window.location.href)
      url.searchParams.delete('page')

      if (value.length) {
        url.searchParams.set('q', value)
      } else {
        url.searchParams.delete('q')
      }

      Turbo.visit(url.href, {
        action: 'replace',
      })
    }, 300)
  }
}
