import { Controller } from '@hotwired/stimulus'
import { fireworks, stars } from '../fireworks'

export default class extends Controller {
  static values = {
    remove: { type: Boolean, default: true },
    type: { type: String, default: 'fireworks' },
  }
  declare removeValue: boolean
  declare typeValue: 'fireworks' | 'stars'

  connect(): void {
    if (this.typeValue === 'stars') {
      stars()
    } else {
      fireworks()
    }

    if (this.removeValue) {
      this.element.remove()
    }
  }
}
